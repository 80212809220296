import Image from 'next/legacy/image';
import { FC, useEffect, useState } from 'react';
import { UseFormTrigger } from 'react-hook-form';

import {
  InputContainer,
  InputIconWrapper,
  InputLabel,
  InputRightText,
  StyledInput,
} from 'styles/inputs';
import { IS_DEV } from 'utils/constants/common';

interface Props {
  type?: InputType;
  value: string | number;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  dataTestId: string;
  rightText?: string;
  onChange: (arg: string | number) => void;
  trigger?: UseFormTrigger<any>;
  name?: string;
  minValue?: number;
  dataTip?: string;
  dataFor?: string;
  isDebounce?: boolean;
}

const Input: FC<Props> = ({
  type = 'text',
  value: initialValue,
  placeholder,
  label = '',
  disabled,
  error = false,
  className,
  dataTestId,
  rightText,
  onChange,
  trigger,
  name,
  minValue,
  dataTip,
  dataFor,
  isDebounce = false,
}) => {
  const [value, setValue] = useState(initialValue);

  const onValueChange = (value: string | number) => {
    onChange(type === 'number' ? (value === '' ? '' : +value) : value);
    trigger?.(name);
  };

  useEffect(() => {
    if (!isDebounce) return;
    const timeout = setTimeout(() => {
      onValueChange(value);
    }, 350);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <InputContainer className={className}>
      <InputLabel
        show
        error={error}
        data-tip={dataTip}
        data-for={dataFor}
        data-testid={IS_DEV && `${dataTestId}-label`}
        aria-describedby={dataFor}
      >
        {label || placeholder}
      </InputLabel>
      <StyledInput
        type={type}
        value={isDebounce ? value : initialValue}
        disabled={disabled}
        error={error}
        withIcon={!!rightText}
        data-testid={IS_DEV && dataTestId}
        placeholder={placeholder}
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        onChange={({ target: { value } }) => {
          isDebounce ? setValue(value) : onValueChange(value);
        }}
        min={type === 'number' && minValue === 0 ? minValue : undefined}
      />
      <InputIconWrapper>
        {rightText && !error && <InputRightText>{rightText}</InputRightText>}
        {error && (
          <Image src="/icons/other/error.svg" width={14} height={14} alt="validation error" />
        )}
      </InputIconWrapper>
    </InputContainer>
  );
};
export default Input;
